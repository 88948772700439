import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { configureStore } from '@/store'
import { Router } from '@/routes'
import '@/lib/firebase'
import { FullScreenLoading } from './context/FullScreenLoadingContext'

const { store, persistor } = configureStore()

export default () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FullScreenLoading>
          <Router />
        </FullScreenLoading>
      </PersistGate>
    </Provider>
  )
}
