import { LayoutProps } from './props'
import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

export default (): LayoutProps => {
  const users = useUsers()
  return {
    users,
  }
}

const useUsers = (): App.User[] => {
  const [users, setUsers] = useState<App.User[]>([])

  useEffect(
    () =>
      firestore
        .collection('users')
        .orderBy('createdAt', 'desc')
        .onSnapshot(async a => {
          const p = await Promise.all(
            a.docs.map<Promise<App.User>>(async b => {
              const data = b.data()
              return {
                ...data,
                id: b.id,
              } as App.User
            }),
          )
          setUsers(p)
        }),
    [],
  )
  return users
}
