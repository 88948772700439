import { useRouteMatch } from 'react-router'
import { logout } from '@/lib/firebase'
import { Props, LayoutProps } from './props'

export default ({ title, children }: Props): LayoutProps => {
  document.title = `${title} | IBS`
  const { path: currentPath } = useRouteMatch()
  const selectedKey = currentSelectedKey(currentPath)
  const openKeyParam = currentOpenKeyParam(selectedKey)

  return {
    selectedKey,
    openKeyParam,
    logout,
    children,
  }
}

const currentSelectedKey = (path: string): string => {
  if (path.match(/^\/notifications/) !== null) {
    return 'notifications'
  } else if (path.match(/^\/news/) !== null) {
    return 'news'
  } else if (path.match(/^\/columns/) !== null) {
    return 'columns'
  } else if (path.match(/^\/users/) !== null) {
    return 'users'
  } else {
    return 'dashboard'
  }
}

const currentOpenKeyParam = (key: string): string => {
  if (key === 'news' || key === 'columns') {
    return 'openKeys=["posts"]'
  } else {
    return ''
  }
}
