import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LayoutProps } from './props'
import { Layout, Menu } from 'antd'
import { NotificationOutlined, EditOutlined, UserOutlined, LineChartOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

export default ({ selectedKey, openKeyParam, logout, children }: LayoutProps) => (
  <RootLayout>
    <Sider collapsible>
      <Logo></Logo>
      <Menu
        theme="dark"
        defaultSelectedKeys={[selectedKey]}
        defaultOpenKeys={['analytics', 'posts']}
        mode="inline">
        <SubMenu
          key="analytics"
          title={
            <span>
              <LineChartOutlined />
              <span>分析</span>
            </span>
          }>
          <Menu.Item key="dashboard">
            <Link to="/">
              <span>ダッシュボード</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="notifications">
          <Link to="/notifications">
            <NotificationOutlined />
            <span>PUSH通知履歴</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="posts"
          title={
            <span>
              <EditOutlined />
              <span>投稿</span>
            </span>
          }>
          <Menu.Item key="news">
            <Link to="/news">ニュース</Link>
          </Menu.Item>
          <Menu.Item key="columns">
            <Link to="/columns">コラム</Link>
          </Menu.Item>
          <Menu.Item key="cases">
            <Link to="/cases">ケース</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="users">
          <Link to="/users">
            <UserOutlined />
            <span>ユーザー</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
    <Layout>
      <StyledHeader>
        <Menu mode="horizontal">
          <Menu.Item>
            <LogoutLink onClick={logout}>ログアウト</LogoutLink>
          </Menu.Item>
        </Menu>
      </StyledHeader>
      <ChildContent>{children}</ChildContent>
      <StyledFooter>
        Created by <a href="https://www.appabrik.com/ja">Appabrik</a>
      </StyledFooter>
    </Layout>
  </RootLayout>
)

const RootLayout = styled(Layout)`
  min-height: 100vh;
`

const Logo = styled.div`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
`

const StyledHeader = styled(Header)`
  padding: 0;
  background: #fff;
  text-align: right;
`

const LogoutLink = styled.div`
  cursor: pointer;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.7;
  }
`

const ChildContent = styled(Content)`
  margin: 0 16px;
`

const StyledFooter = styled(Footer)`
  text-align: center;
`
