import { Moment } from 'moment'

const postRequest = async <T extends {}>(endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'POST',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json() as Promise<T>
}

const putRequest = async (endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'PUT',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const deleteRequest = async (endPoint: string, idToken: string) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'DELETE',
    headers: makeHeaders(idToken),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const baseUrl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`

const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`
}

const makeHeaders = (idToken: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${idToken}`,
})

export const ArticleRequest = {
  postNewArticle: async (
    idToken: string,
    params: {
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return postRequest<{
      article: Pick<App.Article, 'sourceUrl' | 'featuredImageUrls' | 'title' | 'contents' | 'category'>
    }>('api/articles', idToken, {
      article: {
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
  delete: async (idToken: string, params: { id: App.Article['id'] }) => {
    return deleteRequest(`api/articles/${params.id}`, idToken)
  },
  update: async (
    idToken: string,
    params: {
      id: string
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return putRequest(`api/articles/${params.id}`, idToken, {
      article: {
        id: params.id,
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
}
