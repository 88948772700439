import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'

const columns = [
  {
    title: '会社名',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: '氏名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'メールアドレス',
    dataIndex: 'emailAddress',
    key: 'emailAddress',
  },
  {
    title: '登録日時',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (cteatedAt: firebase.firestore.Timestamp) => <span>{cteatedAt.toDate().toLocaleString()}</span>,
  },
]

export type LayoutProps = {
  users: App.User[]
}

export default ({ users }: LayoutProps) => {
  return <UsersTableWidget users={users} />
}

type UsersListProps = {
  users: App.User[]
}

const UsersTableWidget = ({ users }: UsersListProps) => (
  <ContentBody>
    <Table dataSource={users} columns={columns} />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
