import { firestore } from '@/lib/firebase'

export const Api = {
  getArticle: (params: { id: App.Article['id'] }): Promise<App.Article> =>
    firestore
      .collection('articles')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,

  getNotification: (params: { id: App.Notification['id'] }): Promise<App.Notification> =>
    firestore
      .collection('notifications')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,
}
