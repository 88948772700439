import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import moment from 'moment'

type Props = {
  notifications: App.Notification[]
}

export const Layout = (props: Props) => (
  <ContentBody>
    <Table
      dataSource={props.notifications}
      columns={[
        {
          title: 'タイトル',
          dataIndex: 'content',
          key: 'title',
          render: (content: App.Notification['content']) => {
            return <div style={{ fontSize: 14 }}>{content.title}</div>
          },
        },
        {
          title: '状態',
          dataIndex: 'published',
          key: 'published',
          filters: [
            { text: '公開済', value: true },
            { text: '未公開', value: false },
          ],
          onFilter: (value, record) => record.published === value,
          render: (published: boolean) => <span>{published ? '公開済' : '未公開'}</span>,
        },
        {
          title: '送信（予定）日時',
          dataIndex: 'publishedAt',
          key: 'publishedAt',
          sorter: (a, b) => {
            const d1 = a.publishedAt ? moment(a.publishedAt.toDate()).unix() : 0
            const d2 = b.publishedAt ? moment(b.publishedAt.toDate()).unix() : 0
            return d1 - d2
          },
          render: (publishedAt: App.Notification['publishedAt']) => {
            return (
              <div style={{ fontSize: 13, width: 130 }}>
                {publishedAt ? publishedAt.toDate().toLocaleString('ja-JP') : '未設定'}
              </div>
            )
          },
        },
        {
          title: '登録日時',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
          defaultSortOrder: 'descend',
          render: (cteatedAt: App.Notification['createdAt']) => (
            <div style={{ fontSize: 13, width: 130 }}>{cteatedAt.toDate().toLocaleString('ja-JP')}</div>
          ),
        },
      ]}
    />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
