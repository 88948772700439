import React from 'react'
import { createRouter } from './createRouter'
import LoginPage from '@/pages/login'
import { Notifications } from '@/pages/notifications/list'
import Columns from '@/pages/columns'
import News from '@/pages/news'
import Cases from '@/pages/cases'
import { EditArticle } from '@/pages/edit_article'
import { Users } from '@/pages/users'
import { Dashboard } from '@/pages/analytics/dashboard'

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: '/login',
    component: LoginPage,
  },
  {
    needAuth: true,
    path: '/notifications',
    component: Notifications,
  },
  {
    needAuth: true,
    path: '/columns',
    component: Columns,
  },
  {
    needAuth: true,
    path: '/news',
    component: News,
  },
  {
    needAuth: true,
    path: '/columns',
    component: Columns,
  },
  {
    needAuth: true,
    path: '/cases',
    component: Cases,
  },
  {
    needAuth: true,
    path: '/news/new',
    component: () => <EditArticle category="news" />,
  },
  {
    needAuth: true,
    path: '/columns/new',
    component: () => <EditArticle category="column" />,
  },
  {
    needAuth: true,
    path: '/cases/new',
    component: () => <EditArticle category="case" />,
  },
  {
    needAuth: true,
    path: '/news/:id',
    component: ({ params }) => {
      return <EditArticle category="news" articleId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/columns/:id',
    component: ({ params }) => {
      return <EditArticle category="column" articleId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/cases/:id',
    component: ({ params }) => {
      return <EditArticle category="case" articleId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/users',
    component: Users,
  },
  {
    needAuth: true,
    path: '/',
    component: Dashboard,
  },
]

export const Router = createRouter(Routes)
